import Auth from "@aws-amplify/auth";
import {
  useEffect,
  useState
} from "react";
import { RouteComponentProps } from "react-router";
import {
  Box,
  Spacer,
  Spinner,
  useRadioGroup
} from "@chakra-ui/react";
import mixpanel from "mixpanel-browser";
import ActionsListPopover from "src/components/actions-list/ActionsListPopover";
import ActionsListTile from "src/components/actions-list/ActionsListTile";
import { useInView } from "react-intersection-observer";
import { useResetCurrentSectionGroup } from "src/hooks/useResetCurrentSectionGroup";
import RadioSwitch from "src/components/shared/RadioSwitch";
import { useFootprint } from "src/hooks/useFootprint";
import { useQuestionsApi } from "src/api/questions/questions.api";

import { useUsersApi } from "../../api/users/users.api";
import { FullSpace } from "../../components/shared/FullSpace";
import { LoadingPage } from "../../components/shared/LoadingPage";
import { useAuthContext } from "../../hooks/useAuthContext";
import { IntroSection } from "./sections/IntroSection";
import { SavingsSection } from "./sections/SavingsSection";
import theme from "../../components/theme";
import { Wrapper } from "../../components/shared/Wrapper";
import {
  Recommendation,
  RecommendationGeneratorStatus,
  RecommendationStatus
} from "../../api/users/footprint";
import { useCurrentUserContext } from "../../hooks/useCurrentUserContext";
import { Text } from "../../components/text";
import { routes } from "../../constants";
import RecommendationsFootprint from "./RecommendationFootprint";
import OffsettingFootprint from "./OffsettingFootprint";

const OFFSETTING_DISABLED = process.env.REACT_APP_OFFSETTING_DISABLED === "true";

enum Toggles {
  Reduce = "Reduce your carbon",
  Offsetting = "Offsetting",
}

const TOGGLES = Object.values(Toggles);

export const FootprintPage: React.FC<RouteComponentProps> = ({ history }) => {
  useResetCurrentSectionGroup();
  const { authDataState } = useAuthContext();
  const [ { user, loading } ] = useCurrentUserContext();
  const [ username, setUsername ] = useState("");
  const [ getFootprintResponse, getFootprintRequest ] = useUsersApi("GET_FOOTPRINT");
  const [ recommendationsToAction, setRecommendationsToAction ] = useState<Recommendation[]>([]);
  const radioGroupProps = useRadioGroup({ defaultValue: TOGGLES[ 0 ] });
  const [ footprintState, setFootprintState ] = useFootprint();
  const [ reportCreated, setReportCreated ] = useState(false);
  const [ getRecommendationStatusResponse, getRecommendationStatusRequest ] = useQuestionsApi("GET_RECOMMENDATION_STATUS");

  const { ref: inViewRef, entry } = useInView({
    threshold: [
      0.1,
      0.25,
      0.5
    ]
  });

  const isActionsInView = entry && entry?.intersectionRatio >= 0.25;

  useEffect(() => {
    setRecommendationsToAction(footprintState.interestedRecommendations ?? []);

    if (window.chatbotId !== window.dashboardChatbotId) {
      window.location.reload();

      return;
    }

    mixpanel.track("ir_dashboard");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Only perform this request once /users/me has been called at least once
    // This makes sure that the user and property will be correctly linked
    if (user && !loading) {
      getFootprintRequest({});
    }
  }, [
    getFootprintRequest,
    loading,
    user
  ]);

  useEffect(() => {
    if (!footprintState?.footprintScores && getFootprintResponse.data) {
      const interestedRecommendations = getFootprintResponse.data.data.recommendations.filter(recommendation => recommendation.status === RecommendationStatus.CommunicationPending);

      setFootprintState({
        footprintScores: getFootprintResponse.data.data.footprintScores,
        interestedRecommendations
      });
    }

    if (getFootprintResponse.data?.data.recommendationsStatus.status === RecommendationGeneratorStatus.SUCCESS) {
      setReportCreated(true);
    }
  }, [
    footprintState,
    getFootprintResponse.data,
    setFootprintState
  ]);

  useEffect(() => {
    if (reportCreated === false && getRecommendationStatusResponse.data?.data.status === RecommendationGeneratorStatus.SUCCESS) {
      getFootprintRequest({});
      setReportCreated(true);
    }

    if (reportCreated === false && getFootprintResponse.data?.data.recommendationsStatus.status === RecommendationGeneratorStatus.SUCCESS) {
      setReportCreated(true);
    }

    if (reportCreated === false && getFootprintResponse.data && getFootprintResponse.data.data.recommendationsStatus.status === RecommendationGeneratorStatus.CALCULATING) {
      const interval = setInterval(() => {
        getRecommendationStatusRequest({ id: getFootprintResponse.data?.data.recommendationsStatus.id });
      }, 7500);

      return () => {
        clearInterval(interval);
      };
    }
  }, [
    footprintState,
    getFootprintResponse,
    getRecommendationStatusResponse,
    reportCreated,
    getFootprintRequest,
    getRecommendationStatusRequest
  ]);

  useEffect(() => {
    // If the current user doesn't have a property, redirect to the Home Check
    if (getFootprintResponse.error && getFootprintResponse.error.message === "No property for the current user exists.") {
      history.push(routes.section(1));
    }
  });

  useEffect(() => {
    if (authDataState?.authenticated && !username) {
      Auth.currentAuthenticatedUser().then(user => {
        setUsername(`${user.attributes[ "custom:firstName" ]}`);
      });
    }
  }, [ authDataState, username ]);

  if (!user) {
    return (
      <LoadingPage text="Loading Home Check" />
    );
  }

  if (!reportCreated && getFootprintResponse.data?.data?.footprintScores) {
    return (
      <LoadingPage
        text="We are upgrading your dashboard to the newest version"
        subtext="This may take upto 45 seconds"
      />
    );
  }

  return (

    <>

      <IntroSection
        username={username}
        completed={getFootprintResponse?.complete}
      />

      <FullSpace
        color="black"
        backgroundColor={theme.colors.gray[ 100 ]}
        position="sticky"
      >
        <FullSpace
          bg="white"
          p={{ base: 0 }}
          zIndex={1}
        >
          <Box
            paddingInline={{ md: theme.spacing[ 6 ] }}
          >
            <SavingsSection
              isLoading={loading || !username || !getFootprintResponse.data?.data}
              {...(getFootprintResponse.data?.data.footprintScores ?? {})}
            />
          </Box>

          {!OFFSETTING_DISABLED && (
            <>
              <Wrapper
                variant="text"
                paddingBottom={theme.spacing[ 8 ]}
              >
                <RadioSwitch
                  options={TOGGLES}
                  {...radioGroupProps}
                >
                  {({ value, checked }) => (
                    <Text
                      color={checked ? "initial" : theme.colors.gray[ 700 ]}
                      _hover={{ color: "black" }}
                      transition="color 0.2s"
                      py={2}
                      pointerEvents={!getFootprintResponse.data?.data?.footprintScores ? "none" : "auto"}
                      cursor={!getFootprintResponse.data?.data?.footprintScores ? "not-allowed" : "pointer"}
                    >
                      {value}

                      {" "}

                      {value === Toggles.Offsetting && (
                        !getFootprintResponse.data?.data?.footprintScores && (
                          <Spinner size="xs" />
                        )
                      )}
                    </Text>
                  )}
                </RadioSwitch>
              </Wrapper>
            </>
          )}

          {(OFFSETTING_DISABLED || radioGroupProps.value === Toggles.Reduce) && (
            <RecommendationsFootprint
              setRecommendationsToAction={setRecommendationsToAction}
              getFootprintResponse={getFootprintResponse}
              recommendationsToAction={recommendationsToAction}
            />
          )}

          {!OFFSETTING_DISABLED && radioGroupProps.value === Toggles.Offsetting && (
            <OffsettingFootprint
              getFootprintResponse={getFootprintResponse}
            />
          )}
        </FullSpace>

        <Spacer h="50px" />

        <Box
          ref={inViewRef}
          w="100%"
        >
          <ActionsListTile
            list={recommendationsToAction}
          />
        </Box>

        {/* Selected actions list */}

        <ActionsListPopover
          list={recommendationsToAction}
          show={Boolean(recommendationsToAction.length && !isActionsInView)}
        />

      </FullSpace>
    </>
  );
};

export default FootprintPage;
